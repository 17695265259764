import React, {useContext, useEffect, useState} from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from "@mui/material/Divider";
import {State} from 'country-state-city';

import payPal from '../assets/images/PayPal.png'
import Visa from '../assets/images/Visa.png'
import masterCard from '../assets/images/MasterCard.png'
import CartBag from "./CartBag";
import {Link} from "react-router-dom";
import DataContext from "../context/Data";


const CheckoutPage = () => {

    const {
        cartItems,
        estimatedTotal,
        estimatedTax,
        totalCartPrice,
        handleTotalCartPrice,
        handleTotalPayment,
        handleEstimatedTax
    } = useContext(DataContext);

    const textFieldStyle = {
        style: {
            color: '#CCC9C6',
            fontSize: '12px',
            letterSpacing: '1px'
        },
    }

    const countryState = State.getStatesOfCountry('US')
    const [selectedState, setSelectedState] = useState('')
    const [expanded, setExpanded] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleStateChange = (e) => {
        setSelectedState(e.target.value)
    }

    const renderStates = countryState.map((elem) => {
        return (
            <MenuItem sx={{fontSize: '12px'}} value={elem.name}>{elem.name}</MenuItem>
        )
    })

    const handlePaymentAlert = () => {
        setAlertOpen(true)
    }

    useEffect(() => {
        handleTotalPayment();
        handleEstimatedTax();
        handleTotalCartPrice();
        // eslint-disable-next-line
    }, [totalCartPrice, estimatedTotal, estimatedTax])

    return (
        <div className={'checkOut-page'}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-8 col-lg-12 col-md-12 col-12">
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography sx={{width: '33%', flexShrink: 0}}>
                                    Billing Details
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="row">
                                    <div className="col-md-8 mb-4">
                                        <div className="card mb-4">
                                            <div className="card-body">
                                                <form>
                                                    <div className="row mb-4">
                                                        <div className="col">
                                                            <div className="form-outline">
                                                                <TextField
                                                                    InputLabelProps={textFieldStyle}
                                                                    required
                                                                    id="First_Name"
                                                                    label="First Name"
                                                                    defaultValue=""
                                                                    variant={"standard"}
                                                                    color={'warning'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-outline">
                                                                <TextField
                                                                    InputLabelProps={textFieldStyle}
                                                                    required
                                                                    id="Last_Name"
                                                                    label="Last Name"
                                                                    defaultValue=""
                                                                    variant={"standard"}
                                                                    color={'warning'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-outline mb-4">
                                                        <TextField
                                                            InputLabelProps={textFieldStyle}
                                                            id="Company"
                                                            label="Company"
                                                            defaultValue=""
                                                            variant={"standard"}
                                                            color={'warning'}
                                                        />
                                                    </div>

                                                    <div className="form-outline mb-4">
                                                        <TextField
                                                            InputLabelProps={textFieldStyle}
                                                            required
                                                            id="Address"
                                                            label="Address"
                                                            defaultValue=""
                                                            variant={"standard"}
                                                            className={'w-100'}
                                                            color={'warning'}
                                                        />
                                                    </div>

                                                    <div className="row mb-4">
                                                        <div className="col">
                                                            <div className="form-outline">
                                                                <TextField
                                                                    InputLabelProps={textFieldStyle}
                                                                    required
                                                                    id="City"
                                                                    label="City/Town"
                                                                    defaultValue=""
                                                                    variant={"standard"}
                                                                    className={'w-100'}
                                                                    color={'warning'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-outline">
                                                                <FormControl variant="standard" sx={{minWidth: 150}}>
                                                                    <InputLabel id="demo-simple-select-standard-label"
                                                                                sx={{
                                                                                    color: 'black',
                                                                                    '&.Mui-focused': {
                                                                                        color: '#ff931e',
                                                                                    },
                                                                                    '&.MuiInputLabel-shrink': {
                                                                                        color: '#ff931e',
                                                                                    },
                                                                                }}>State
                                                                        *</InputLabel>
                                                                    <Select
                                                                        InputLabelProps={textFieldStyle}
                                                                        required
                                                                        labelId="State"
                                                                        id="State"
                                                                        value={selectedState}
                                                                        label="State *"
                                                                        onChange={(e) => handleStateChange(e)}
                                                                        className={'w-100'}
                                                                        color={'warning'}
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>None</em>
                                                                        </MenuItem>
                                                                        {renderStates}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-outline">
                                                                <TextField
                                                                    InputLabelProps={textFieldStyle}
                                                                    disabled
                                                                    id="Country"
                                                                    label="Country"
                                                                    defaultValue="United States"
                                                                    variant={"standard"}
                                                                    color={'warning'}
                                                                    className={'w-100'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-outline">
                                                                <TextField
                                                                    InputLabelProps={textFieldStyle}
                                                                    required
                                                                    id="Postal_Code"
                                                                    label="Postal Code"
                                                                    defaultValue=""
                                                                    variant={"standard"}
                                                                    color={'warning'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-outline mb-4">
                                                        <TextField
                                                            InputLabelProps={textFieldStyle}
                                                            id="Email"
                                                            label="Email"
                                                            defaultValue=""
                                                            variant={"standard"}
                                                            className={'w-100'}
                                                            color={'warning'}
                                                        />
                                                    </div>

                                                    <div className="form-outline mb-4">
                                                        <TextField
                                                            InputLabelProps={textFieldStyle}
                                                            required
                                                            id="Phone"
                                                            label="Phone"
                                                            defaultValue=""
                                                            variant={"standard"}
                                                            className={'w-100'}
                                                            color={'warning'}
                                                        />
                                                    </div>


                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value=""
                                                               id="checkoutForm1"/>
                                                        <label className="form-check-label" htmlFor="checkoutForm1">
                                                            Shipping address is the same as my billing address
                                                        </label>
                                                    </div>

                                                    <div className="form-check mb-4">
                                                        <input className="form-check-input" type="checkbox" value=""
                                                               id="checkoutForm2" checked/>
                                                        <label className="form-check-label" htmlFor="checkoutForm2">
                                                            Save this information for next time
                                                        </label>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>


                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <Typography sx={{width: '33%', flexShrink: 0}}>Payment</Typography>
                                <div className={'d-flex gap-3 py-2'}>
                                    <img src={Visa} alt=""/>
                                    <img src={masterCard} alt=""/>
                                    <img src={payPal} alt=""/>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="row">
                                    <div className="col-md-8 mb-4">
                                        {alertOpen && <Alert severity="error">
                                            <AlertTitle>Error</AlertTitle>
                                            Sorry your payment wasn't processed. Please check your payment details or
                                            try another payment method.
                                        </Alert>}
                                        <div className="card mb-4">
                                            <div className="card-body">
                                                <form>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio"
                                                               name="flexRadioDefault" id="Credit_card"
                                                               checked/>
                                                        <label className="form-check-label" htmlFor="Credit_card">
                                                            Credit card
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input disabled className="form-check-input" type="radio"
                                                               name="flexRadioDefault" id="Debit_card"/>
                                                        <label className="form-check-label" htmlFor="Debit_card">
                                                            Debit card
                                                        </label>
                                                    </div>

                                                    <div className="form-check mb-4">
                                                        <input disabled className="form-check-input" type="radio"
                                                               name="flexRadioDefault" id="Paypal"/>
                                                        <label className="form-check-label" htmlFor="Paypal">
                                                            Paypal
                                                        </label>
                                                    </div>
                                                    <div className="row mb-4">
                                                        <div className="col">
                                                            <div className="form-outline">
                                                                <TextField
                                                                    InputLabelProps={textFieldStyle}
                                                                    required
                                                                    id="Name_on_Card"
                                                                    label="Name on Card"
                                                                    defaultValue=""
                                                                    variant={"standard"}
                                                                    className={'w-100'}
                                                                    color={'warning'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-outline">
                                                                <TextField
                                                                    InputLabelProps={textFieldStyle}
                                                                    required
                                                                    id="Credit_Card_Number"
                                                                    label="Credit Card Number"
                                                                    defaultValue=""
                                                                    variant={"standard"}
                                                                    className={'w-100'}
                                                                    color={'warning'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-4">
                                                        <div className="col-3">
                                                            <div className="form-outline">
                                                                <TextField
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                        style: {
                                                                            color: '#CCC9C6',
                                                                            fontSize: '12px',
                                                                            letterSpacing: '1px'
                                                                        }
                                                                    }}
                                                                    required
                                                                    type={'date'}
                                                                    id="Expiration"
                                                                    label="Expiration"
                                                                    defaultValue=""
                                                                    variant={"standard"}
                                                                    className={'w-100'}
                                                                    color={'warning'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="form-outline">
                                                                <TextField
                                                                    InputLabelProps={textFieldStyle}
                                                                    required
                                                                    type={'password'}
                                                                    id="CCV"
                                                                    label="CCV"
                                                                    defaultValue=""
                                                                    variant={"standard"}
                                                                    className={'w-100'}
                                                                    color={'warning'}
                                                                    inputProps={{
                                                                        maxLength: 3,
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <button className="btnOne font-openSans" onClick={handlePaymentAlert}>
                                                    Continue to checkout
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <div className={'mt-5 text-center'}>
                            <Link to={'/cart'}>
                                <button className={'btnOne font-openSans'}>Back to Cart</button>
                            </Link>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-12 col-md-12 col-12 mt-xl-0 mt-lg-5 mt-5 d-flex flex-column gap-5">
                        <div className='row'>
                            <div className="col-12">
                                <h3 className={'font-openSans font-28'}>Order Summary</h3>
                                <Divider color={'#ff931e'}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className={'d-flex justify-content-between'}>
                                    <p className={'font-openSans'}>Items</p>
                                    <p className={'font-futura'}>$ {estimatedTotal}</p>
                                </div>
                                <div className={'d-flex justify-content-between'}>
                                    <p className={'font-openSans'}>Shipping Cost</p>
                                    <p className={'font-futura'}>Free</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className={'d-flex justify-content-between'}>
                                    <p className={'font-openSans'}>Estimated Tax</p>
                                    <p className={'font-futura'}>$ {estimatedTax}</p>
                                </div>
                                <Divider color={'#ff931e'}/>
                                <div className={'d-flex justify-content-between'}>
                                    <p className={'font-openSans font-21 mt-4'}>Total Cost</p>
                                    <p className={'font-futura font-21 mt-4'}>$ {totalCartPrice}</p>
                                </div>
                            </div>
                        </div>
                        <Divider color={'#ff931e'}/>
                        <div className="row">
                            <div className="col-12">
                                <CartBag cartBagItems={cartItems}/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default CheckoutPage;