


export const faqsData = [
    {
        id: '1',
        question:'What types of products does your online store offer?',
        answer: 'Our online store offers a diverse range of products to cater to various needs and preferences. Our product categories include but are not limited to electronics, home and kitchen appliances, beauty and personal care, sports and outdoor equipment, toys and games, and much more. We strive to provide a wide selection of high-quality products that meet the demands of our valued customers.',
    },
    {
        id: '2',
        question:'How do I place an order on your online store?',
        answer: 'Placing an order on our online store is simple and user-friendly. Follow these steps:\n' +
            'a. Browse our website and select the desired product(s).\n' +
            'b. Add the selected item(s) to your shopping cart.\n' +
            'c. Proceed to checkout and fill in the necessary details such as shipping address and payment method.\n' +
            'd. Review your order to ensure accuracy.\n' +
            'e. Click on "Place Order" to complete your purchase.\n' +
            'Once your order is confirmed, you will receive an email with the order details and shipping information.',
    },
    {
        id: '3',
        question:'What payment methods do you accept?',
        answer: 'We accept various payment methods to provide convenience and flexibility to our customers. Currently, we accept credit and debit cards from major providers like Visa, MasterCard, and American Express.',
    },
    {
        id: '4',
        question:'What is your shipping and return policy?',
        answer: 'Our shipping policy ensures prompt and reliable delivery of your orders. We strive to process and dispatch orders within a specified timeframe (usually 1-3 business days). Shipping costs and delivery times may vary depending on your location and the chosen shipping method. During the checkout process, you will have the option to select the shipping method that best suits your needs.Regarding returns, we want our customers to be satisfied with their purchases. If you receive a defective or damaged product, please contact our customer support within 7 days of delivery to initiate a return process. We also offer a hassle-free return policy for items that don\'t meet your expectations, subject to certain conditions. For more detailed information, please refer to our dedicated Shipping and Returns page on our website or reach out to our customer support team for assistance.',
    },
    {
        id: '5',
        question:'Can I track the status of my order after purchase?',
        answer: 'Yes, you can track the status of your order after purchase. We understand the importance of staying informed about the progress of your delivery. Once your order has been processed and shipped, we will provide you with a tracking number via email or SMS (depending on your chosen communication preference). You can use this tracking number to monitor the real-time status of your package and its estimated delivery date.',
    },

]