import React, {useContext, useEffect, useState} from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';

import ProductCard from "./ProductCard";

import {Global_Data} from '../data/ProductsData';
import PageNumber from "./PageNumber";
import DataContext from "../context/Data";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";


const ProductListPage = () => {

    const {handleSearchTerm, searchTerm} = useContext(DataContext);

    const textFieldStyle = {
        style: {
            color: '#CCC9C6',
            fontSize: '12px',
            letterSpacing: '1px'
        },
    };


    const {handleLinkClick} = useContext(DataContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const from = queryParams.get('from');
    const {categoryId} = useParams();


    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [categoryProducts, setCategoryProducts] = useState({});
    const [filterValue, setFilterValue] = useState('');
    const filterTitles = ['None', 'Alphabetically A-Z', 'Price-Highest to Lowest', 'Price-Lowest to Highest']

    const prodPerPage = 6;
    const lastProdIndex = currentPage * prodPerPage;
    const firstProdIndex = lastProdIndex - prodPerPage;
    const currentProds = categoryProducts.products
        ? categoryProducts.products.slice(firstProdIndex, lastProdIndex)
        : [];

    const handlePageChange = (event, value) => {
        setCurrentPage(value)
        setPage(value);
    };

    const renderFilterTitles = filterTitles.map((elem) => {
        return (
            <MenuItem value={elem}>{elem}</MenuItem>
        )
    });

    const filterProducts = () => {
        if (filterValue === "" || filterValue === "None") {
            if (searchTerm) {
                const filteredCategoryProducts = {...categoryProducts};
                const filteredProducts = filteredCategoryProducts.products.filter((product) =>
                    product.product_title.toLowerCase().includes(searchTerm.toLowerCase())
                );
                filteredCategoryProducts.products = filteredProducts;
                setCategoryProducts(filteredCategoryProducts);
            }
        } else if (filterValue === 'Alphabetically A-Z') {
            const filteredCategoryProducts = {...categoryProducts};
            const filteredProducts = filteredCategoryProducts.products.sort((a, b) => {
                const titleA = a.product_title;
                const titleB = b.product_title;
                if (titleA < titleB) {
                    return -1; // a should be before b
                }
                if (titleA > titleB) {
                    return 1; // a should be after b
                }
                return 0;
            });
            filteredCategoryProducts.products = filteredProducts;
            setCategoryProducts(filteredCategoryProducts);
        }
    };


    useEffect(() => {
        // Fetch and display products based on the categoryId
        window.scrollTo(0, 0);
        if (from === 'productCategory') {
            const filteredProducts = Global_Data.find(
                (product) => product.category_id === Number(categoryId)
            );
            setCategoryProducts(filteredProducts);
        } else if (from === 'shopAll') {
            setCategoryProducts({
                category_id: null,
                category_name: 'Shop All',
                products: Global_Data.flatMap(category => category.products)
            });
        } else {
            const filteredProducts = {
                category_id: null,
                category_name: from,
                products: Global_Data.flatMap(category => category.products).filter(product => product.product_brand === from),
            };
            setCategoryProducts(filteredProducts);
        }

        filterProducts();
// eslint-disable-next-line
    }, [categoryId, from, filterValue, searchTerm]);


    return (
        <div className={'product-list-page'}>
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="product-list-banner col-12 d-flex justify-content-center align-items-center shadow-lg mb-5 bg-dark rounded">
                        <h1 className={'font-openSans font-28'}>{categoryProducts.category_name}</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row border-bottom border-2 border-warning">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <FormControl variant="standard" sx={{mb: 1, minWidth: 220}}>
                                <InputLabel id="demo-simple-select-standard-label"
                                            sx={{
                                                fontSize: '13px',
                                                color: 'black',
                                                '&.Mui-focused': {
                                                    color: '#ff931e',
                                                },
                                                '&.MuiInputLabel-shrink': {
                                                    color: '#ff931e',
                                                },
                                            }}>Filter Products</InputLabel>
                                <Select
                                    InputLabelProps={textFieldStyle}
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={filterValue}
                                    onChange={(e) => setFilterValue(e.target.value)}
                                    label="Age"
                                    color={'warning'}
                                >
                                    {renderFilterTitles}
                                </Select>
                            </FormControl>
                            <div
                                className={'d-xl-flex d-lg-flex d-md-flex d-none justify-content-center align-items-center'}>
                                <SearchIcon/>
                                <input onChange={(e) => handleSearchTerm(e.target.value)} className={'search-input'}
                                       type="text"
                                       placeholder={"Search Products"}/>
                            </div>
                            {categoryProducts.products &&
                                <p className={'font-futura font-15 mb-0'}>{categoryProducts.products.length} products</p>}
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center align-items-center flex-wrap gap-3 mt-5">
                            <ProductCard productData={currentProds}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center mt-4">
                            <Link to={'/'}>
                                <button onClick={() => handleLinkClick('Home')} className={'btnOne font-openSans'}>Back
                                    to home
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center mt-5">
                            {categoryProducts.products && (
                                <PageNumber
                                    page={page}
                                    handlePageChange={handlePageChange}
                                    totalProds={categoryProducts.products.length}
                                    prodPerPage={prodPerPage}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductListPage;