import React from 'react';

const Newsletter = () => {
    return (
        <div className={'newsletter-sec'}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center">
                        <h2 className={'font-openSans font-28'}>JOIN TROVE ZONE NOW</h2>
                        <p className={'font-futura font-15 mt-3'}>Sign Up Now To Be The First To Hear From Us</p>
                        <div className={'d-flex justify-content-center align-items-center mt-5'}>
                            <input type="text" placeholder={'Enter your email'}/>
                            <button className={'btnOne font-openSans'}>Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Newsletter;