import React, {useContext, useEffect} from 'react';
import {Link} from "react-router-dom";
import DataContext from "../context/Data";

const AboutUs = () => {

    const {handleLinkClick} = useContext(DataContext);

    useEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className={'about-us-section'}>
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center align-items-center flex-column">
                        <h1 className={'font-openSans font-28'}>About US</h1>
                        <p className={'font-futura font-21 mt-4 text-center'}>Trove Zone Shop is an online eCommerce
                            company dedicated to providing a wide range of premium products and delivering exceptional
                            customer service.</p>
                        <p className={'font-futura font-21 mt-2 text-center'}>Our extensive product categories include
                            sports and outdoor gear, health and household products, home and kitchen goods, and beauty
                            and personal care items. We take pride in embracing the diverse needs and preferences of our
                            esteemed customers.</p>
                        <p className={'font-futura font-21 mt-2 text-center'}>Whether you're seeking high-performance
                            sports equipment, essential wellness products, stylish home goods, or top-notch beauty
                            essentials, Trove Zone Shop is your ultimate destination. We strive to ensure a seamless
                            shopping experience characterized by secure payments, reliable shipping, and responsive
                            customer support. At Trove Zone Shop, we are committed to your satisfaction.</p>
                        <p className={'font-futura font-21 mt-2 text-center'}>Discover the convenience, quality, and
                            limitless possibilities that await you at Trove Zone Shop.</p>
                        <Link onClick={() => handleLinkClick('Home')} to={'/'}>
                            <button className={'btnOne font-openSans mt-5'}>Back to home</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;