import React from 'react';
import ProductCard from "./ProductCard";
import {Link} from "react-router-dom";


const ProductCategoryView = ({productData, itemsToShow}) => {

    let sixProducts = []

    if (productData && productData.products) {
        sixProducts = productData.products.slice(0, itemsToShow)
    } else {
        sixProducts = []
    }


    return (
        <div className={'product-category mt-5'}>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h2 className={'font-openSans font-28'}>{productData.category_name}</h2>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="product-card-row col-12 d-flex flex-wrap gap-3 justify-content-center ">
                        <ProductCard productData={sixProducts}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 text-center">
                        <Link to={`/product-list/${productData.category_id}?from=productCategory`}>
                            <button className={'btnOne font-openSans mb-5'}>VIEW ALL</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCategoryView;