import React from "react";
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import {Route, Routes} from "react-router-dom";

import HomePage from "./components/HomePage";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import ProductItemPage from "./components/ProductItemPage";
import CartPage from "./components/CartPage";
import BrandsPage from "./components/BrandsPage";
import ProductListPage from "./components/ProductListPage";
import {Provider} from "./context/Data";
import CheckoutPage from "./components/CheckoutPage";
import {useEffect, useState} from "react";
import Loader from "./components/Loader";
import Faq from "./components/Faq";
import AboutUs from "./components/AboutUs";


function App() {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate a 2-second delay
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000);

        return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
    }, []);



    return (
        <Provider>
            {isLoading? <Loader /> :
                <div className="App">
                <section>
                    <div className={'ticker bg-theme-color py-1'}>
                        <h6 className={'text-center mt-1 text-white font-14'}>QUALITY AND CUSTOMER SATISFACTION IS OUR
                            TOP
                            PRIORITY</h6>
                    </div>
                    <Nav/>
                </section>

                <Routes>
                    <Route path={'/'} element={<HomePage/>}/>
                    <Route path={'/product/:productId'} element={<ProductItemPage/>}/>
                    <Route path={'/cart'} element={<CartPage/>}/>
                    <Route path={'/brands'} element={<BrandsPage/>}/>
                    <Route path={'/product-list/:categoryId'} element={<ProductListPage/>}/>
                    <Route path={'/checkout'} element={<CheckoutPage/>}/>
                    <Route path={'/faq'} element={<Faq/>}/>
                    <Route path={'/about-us'} element={<AboutUs/>}/>
                </Routes>

                <Footer/>
            </div>}
        </Provider>
    );
}

export default App;
