import brandOne from "../assets/images/brand1.png";
import brandTwo from "../assets/images/brand2.png";
import brandThree from "../assets/images/brand3.png";
import brandFour from "../assets/images/brand4.png";
import brandFive from "../assets/images/brand5.png";
import brandSix from "../assets/images/brand6.png";
import brandSeven from "../assets/images/brand7.jpg";
import brandEight from "../assets/images/brand8.png";
import brandNine from "../assets/images/brand9.png";
import brandTen from "../assets/images/brand10.png";
import brandEleven from "../assets/images/brand11.jpg";
import brandTwelve from "../assets/images/brand12.png";
import brandThirteen from "../assets/images/brand13.png";
import brandFourteen from "../assets/images/brand14.png";
import brandFifteen from "../assets/images/brand15.jpg";
import brandSixteen from "../assets/images/brand16.png";
import brandSeventeen from "../assets/images/brand17.png";


export const brands = [
    {
        id: 1,
        image: brandOne,
        name: 'Ateco',
    },
    {
        id: 2,
        image: brandTwo,
        name: 'Arm & Hammer',
    },
    {
        id: 3,
        image: brandThree,
        name: 'Bath & Body Works',
    },
    {
        id: 4,
        image: brandFour,
        name: 'Mrs. Meyer\'s',
    },
    {
        id: 5,
        image: brandFive,
        name: 'Brita',
    },
    {
        id: 6,
        image: brandSix,
        name: 'Cold Steel',
    },
    {
        id: 7,
        image: brandSeven,
        name: 'Brentwood',
    },
    {
        id: 8,
        image: brandEight,
        name: 'Cuisnart',
    },
    {
        id: 9,
        image: brandNine,
        name: 'Glad',
    },
    {
        id: 10,
        image: brandTen,
        name: 'Havalon',
    },
    {
        id: 11,
        image: brandEleven,
        name: 'Irish Spring',
    },
    {
        id: 12,
        image: brandTwelve,
        name: 'KA-Bar',
    },
    {
        id: 13,
        image: brandThirteen,
        name: 'Little Trees',
    },
    {
        id: 14,
        image: brandFourteen,
        name: 'Lysol',
    },
    {
        id: 15,
        image: brandFifteen,
        name: 'Naglene',
    },
    {
        id: 16,
        image: brandSixteen,
        name: 'Biofreeze',
    },
    {
        id: 17,
        image: brandSeventeen,
        name: 'SOG',
    },

]
