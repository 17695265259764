import React, {useContext} from 'react';

import Divider from '@mui/material/Divider';
import masterCard from "../assets/images/MasterCard.png";
import payPal from "../assets/images/PayPal.png";
import visa from "../assets/images/Visa.png";
import {Link} from "react-router-dom";
import DataContext from "../context/Data";

const Footer = () => {

    const {handleLinkClick} = useContext(DataContext);

    return (
        <div className={'footer-sec'}>
            <div className="container-fluid">
                <div className={'container footer-content'}>
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                            <ul className={'font-openSans font-21'}>QUICK LINKS
                                <div className={'mt-3'}>
                                    <Link to={'/product-list/1?from=productCategory'}>
                                        <li className={'font-futura font-15'}>Sports & Outdoor</li>
                                    </Link>
                                    <Link to={'/product-list/2?from=productCategory'}>
                                        <li className={'font-futura font-15'}>Health & Household</li>
                                    </Link>
                                    <Link to={'/product-list/3?from=productCategory'}>
                                        <li className={'font-futura font-15'}>Home & Kitchen</li>
                                    </Link>
                                    <Link to={'/product-list/4?from=productCategory'}>
                                        <li className={'font-futura font-15'}>Beauty & Personal Care</li>
                                    </Link>
                                </div>
                            </ul>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                            <ul className={'font-openSans font-21'}>CUSTOMER CARE
                                <div className={'mt-3'}>
                                    <Link onClick={() => handleLinkClick('About Us')} to={'/about-us'}>
                                        <li className={'font-futura font-15'}>About Us</li>
                                    </Link>
                                    <Link to={'/'}>
                                        <li className={'font-futura font-15'}>Contact Us</li>
                                    </Link>
                                    <Link onClick={() => handleLinkClick('FAQs')} to={'/faq'}>
                                        <li className={'font-futura font-15'}>FAQs</li>
                                    </Link>
                                    <Link to={'/'}>
                                        <li className={'font-futura font-15'}>Track Your Order</li>
                                    </Link>
                                </div>
                            </ul>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                            <ul className={'font-openSans font-21'}>OUR POLICIES
                                <div className={'mt-3'}>
                                    <Link to={'/'}>
                                        <li className={'font-futura font-15'}>Privacy Policy</li>
                                    </Link>
                                    <Link to={'/'}>
                                        <li className={'font-futura font-15'}>Refund Policy</li>
                                    </Link>
                                    <Link to={'/'}>
                                        <li className={'font-futura font-15'}>Shipping Policy</li>
                                    </Link>
                                    <Link to={'/'}>
                                        <li className={'font-futura font-15'}>Terms of Service</li>
                                    </Link>
                                </div>
                            </ul>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                            <ul className={'font-openSans font-21'}>WHO WE ARE?
                                <div className={'mt-3'}>
                                    <li className={'font-futura font-15'}>We Have A Great Team And Aim To Grow Our
                                        Business More And More By Offering Our Customers The Trending Products Available
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <Divider className={'mb-5'} light={false} color={'black'}/>
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <div>
                                <div className={'mb-2'}>
                                    <img className={'mx-1'} src={masterCard} alt="masterCard"/>
                                    <img className={'mx-1'} src={payPal} alt="payPal"/>
                                    <img className={'mx-1'} src={visa} alt="visa"/>
                                </div>
                            </div>
                            <p className={'font-futura font-15 text-center m-0'}>© 2023, Trove Zone</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Footer;