import React, {useContext, useEffect, useState} from 'react';


import {Rating} from "@mui/material";

import masterCard from '../assets/images/MasterCard.png';
import payPal from '../assets/images/PayPal.png';
import visa from '../assets/images/Visa.png';
import {Link, useParams} from "react-router-dom";
import {Global_Data} from "../data/ProductsData";
import Loader from "./Loader";
import DataContext from "../context/Data";

const ProductItemPage = () => {

    const {cartItems, handleAddToCart, handleRemoveFromCart} = useContext(DataContext);
    const {productId} = useParams();

    const [productItem, setProductItem] = useState({});
    const [inCart, setInCart] = useState(false);
    const [isAddedToCart, setIsAddedToCart] = useState(false);

    const handleInCart = () => {
        if (cartItems) {
            let check = cartItems.findIndex((elem) => elem.product_id === Number(productId))
            if (check === -1) {
                setInCart(false)
            } else {
                setInCart(true)
            }
        }
    };


    const handleRemoveCartItem = (event, id) => {
        handleInCart();
        handleRemoveFromCart(id);
        event.stopPropagation();
    };


    const handleAddCartItem = (event, id) => {
        setIsAddedToCart(true)
        handleInCart()
        setTimeout(() => {
            handleAddToCart(id);
            setIsAddedToCart(false)
        }, 1000)
    };


    useEffect(() => {
        window.scrollTo(0, 0)
        handleInCart();
        let foundProduct = null;
        Global_Data.find((elem) => {
            const item = elem.products.find((elem) => elem.product_id === Number(productId));
            if (item) {
                foundProduct = item;
            }
            if (foundProduct) {
                setProductItem(foundProduct);
            } else {
                setProductItem({});
            }
            return null
        });
        //eslint-disable-next-line
    }, [productId, cartItems])


    return (
        <div className={'product-item-page'}>
            {Object.keys(productItem).length > 0 ? <div className={'container mt-5'}>
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12 text-center">
                        <img className={'w-50'} src={productItem.product_image} alt={productItem.product_title}/>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12  d-flex flex-column justify-content-between">
                        <div className="row">
                            <div className="col-12">
                                <h4>{productItem.product_title}</h4>
                                <Rating name="read-only" value={4} readOnly/>
                                <h5 className={'mt-2'}>Price: ${productItem.product_price}</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-5">
                                <div>
                                    {!inCart ? <button onClick={(e) => handleAddCartItem(e, productItem.product_id)}
                                                       className={'btnTwo font-openSans w-75'}>{!isAddedToCart ? 'Add to Cart' : 'Adding...'}</button> :
                                        <button onClick={(e) => handleRemoveCartItem(e, productItem.product_id)}
                                                className={'btnTwo removeBtn font-openSans w-75'}>Remove from
                                            Cart</button>}
                                    <Link to={'/checkout'}>
                                        <button className={'btnOne font-openSans mt-2 w-75'}>Checkout</button>
                                    </Link>
                                </div>
                                <div className={'mt-5'}>
                                    <p className={'font-futura font-14'}>Secure and trusted checkout with</p>
                                    <div className={''}>
                                        <img className={'mx-1'} src={masterCard} alt="masterCard"/>
                                        <img className={'mx-1'} src={payPal} alt="payPal"/>
                                        <img className={'mx-1'} src={visa} alt="visa"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-xl-8 col-lg-8 col-md-8 col-12">
                                <h3 className={'font-openSans font-28'}>DESCRIPTION:</h3>
                                <p className={"mt-3"}>{productItem.product_description}</p>
                                <div>
                                    <h3 className={'font-openSans font-28'}>SPECIFICATION:</h3>
                                    <p className={'font-futura'}>Edge Angles - 20 Degrees Overall length 11.875 inches
                                        1095 Cro-van steel Leather Handle.</p>
                                    <ul className={'mt-3 font-futura'}>
                                        <li><strong className={'font-openSans'}>Manufacturer Part
                                            #</strong> {productItem.product_manufacturer_part}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div> : <Loader/>}
        </div>
    );
};

export default ProductItemPage;