import React from 'react';
import Loader from "./Loader";
import {Link} from "react-router-dom";


const ProductCard = ({productData}) => {

    if (!productData) {
        return (
            <Loader/>
        )
    }

    const renderProduct = productData.map((elem) => {
        return (
            <div className={'product-card shadow-sm p-3 mb-5 bg-white rounded'}>
                <Link to={`/product/${elem.product_id}`} className={'text-dark'}>
                    <div className="row">
                        <div className="col-12">
                            <div className={'image-container text-center'}>
                                <img className={''} src={elem.product_image} alt=""/>
                            </div>
                            <div className={'product-card-content'}>
                                <h6 className={'font-openSans font-21'}>{elem.product_title}</h6>
                                <p className={'font-openSans font-21'}>${elem.product_price}</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        )
    })


    return (
        <>
            {renderProduct}
        </>

    );
};

export default ProductCard;