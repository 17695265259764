import React, {useContext, useEffect} from 'react';


import Divider from "@mui/material/Divider";
import {Link} from "react-router-dom";
import CartBag from "./CartBag";
import DataContext from "../context/Data";


const CartPage = () => {

    const {
        cartItems,
        totalCartPrice,
        estimatedTax,
        estimatedTotal,
        handleEstimatedTax,
        handleTotalCartPrice
    } = useContext(DataContext);


    useEffect(() => {
        handleEstimatedTax();
        handleTotalCartPrice();
    });


    return (
        <div className={'cart-main container-fluid '}>
            <div className="row d-flex justify-content-center gap-5">
                <div className="col-xl-7 col-lg-7 col-md-12 col-12">
                    <CartBag cartBagItems={cartItems}/>
                </div>


                <div className="col-xl-4 col-lg-4 col-md-12 col-12 payment-summary-layout d-flex flex-column gap-5">
                    <div className='row'>
                        <div className="col-12">
                            <h3 className={'font-openSans font-28'}>Payment Summary</h3>
                            <Divider color={'#ff931e'}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className={'d-flex justify-content-between'}>
                                <p className={'font-openSans font-21'}>Items</p>
                                <p className={'font-futura font-21'}>$ {estimatedTotal}</p>
                            </div>
                            <div className={'d-flex justify-content-between'}>
                                <p className={'font-openSans font-21'}>Shipping Cost</p>
                                <p className={'font-futura font-21'}>Free</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className={'d-flex justify-content-between'}>
                                <p className={'font-openSans font-21'}>Estimated Tax</p>
                                <p className={'font-futura font-21'}>$ {estimatedTax}</p>
                            </div>
                            <Divider color={'#ff931e'}/>
                            <div className={'d-flex justify-content-between'}>
                                <p className={'font-openSans font-21 mt-4'}>Total Cost</p>
                                <p className={'font-futura font-21 mt-4'}>$ {totalCartPrice}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <Link to={'/checkout'}>
                                        <button className={'btnOne font-openSans w-100 mt-2'}>Checkout</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default CartPage;