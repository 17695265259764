import React, {useContext, useEffect, useState} from 'react';
import Banner from "./Banner";
import Newsletter from "./Newsletter";
import ProductCategoryView from "./ProductCategoryView";

import brandOne from '../assets/images/brand1.png';
import brandTwo from '../assets/images/brand2.png';
import brandThree from '../assets/images/brand3.png';
import brandFour from '../assets/images/brand4.png';
import brandFive from '../assets/images/brand5.png';
import sportsOutdoor from '../assets/images/Sports_Outdoor.png';
import healthHousehold from '../assets/images/Health_Household.png';
import homeKitchen from '../assets/images/Home_Kitchen.png';
import beautyPersonalCare from '../assets/images/Beauty_Personal _Care.png';
import homeKitchenImage from '../assets/images/home_kitchen_image.jpg'


import {Global_Data} from "../data/ProductsData";


import {Link} from "react-router-dom";
import DataContext from "../context/Data";


const HomePage = () => {

    const {handleLinkClick} = useContext(DataContext);

    const [healthHouseholdCategory, setHealthHouseholdCategory] = useState({});
    const [homeKitchenCategory, setHomeKitchenCategory] = useState({});
    const [beautyCategory, setBeautyCategory] = useState({});


    useEffect(() => {
        Global_Data.map((elem) => {
            if (elem.category_id === 2) {
                setHealthHouseholdCategory(elem)
            } else if (elem.category_id === 3) {
                setHomeKitchenCategory(elem)
            } else {
                setBeautyCategory(elem)
            }
            return null
        })
    }, [])

    return (
        <div className={'homepage'}>
            <Banner/>
            <section className={'homepage-brand'}>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2 className={'font-openSans font-28'}>OUR BRANDS</h2>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <div
                                    className={'homepage-brand-img d-flex flex-xl-row flex-lg-row flex-md-row flex-column justify-content-center align-items-center gap-5'}>
                                    <Link onClick={() => handleLinkClick('Our Brands')} to={'/brands'}><img
                                        src={brandOne} alt="brandOne"/></Link>
                                    <Link onClick={() => handleLinkClick('Our Brands')} to={'/brands'}><img
                                        src={brandTwo} alt="brandTwo"/></Link>
                                    <Link onClick={() => handleLinkClick('Our Brands')} to={'/brands'}><img
                                        src={brandThree} alt="brandThree"/></Link>
                                    <Link onClick={() => handleLinkClick('Our Brands')} to={'/brands'}><img
                                        src={brandFour} alt="brandFour"/></Link>
                                    <Link onClick={() => handleLinkClick('Our Brands')} to={'/brands'}><img
                                        src={brandFive} alt="brandFour"/></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={'homepage-about'}>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center px-5">
                                <h2 className={'font-openSans'}>ABOUT TROVE ZONE</h2>
                                <p className={'font-futura font-15 mt-3'}>Trove Zone Shop is an online eCommerce company dedicated to providing a wide range of premium products and delivering exceptional customer service. </p>
                                <p className={'font-futura font-15 mt-3'}>Our extensive product categories include sports and outdoor gear, health and household products, home and kitchen goods, and beauty and personal care items.</p>
                                <Link to="about-us"><strong onClick={() => handleLinkClick('About Us')}
                                                            className={'font-15 font-openSans mt-3'}>READ MORE</strong></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className={'homepage-shopping-section'}>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className={'homepage-category-img d-flex flex-xl-row flex-lg-row flex-md-row flex-column justify-content-center gap-3'}>
                                    <div className={'text-center'}>
                                        <img src={sportsOutdoor} alt="sportsOutdoor"/>
                                        <p className={'font-futura font-15 mt-4'}>Sports & Outdoor</p>
                                        <Link to={'/product-list/1?from=productCategory'}>
                                            <button className={'btnTwo mt-2'}>SHOP NOW</button>
                                        </Link>
                                    </div>
                                    <div className={'text-center'}>
                                        <img src={healthHousehold} alt="healthHousehold"/>
                                        <p className={'font-futura font-15 text-center mt-4'}>Health & Household</p>
                                        <Link to={'/product-list/2?from=productCategory'}>
                                            <button className={'btnTwo mt-2'}>SHOP NOW</button>
                                        </Link>
                                    </div>
                                    <div className={'text-center'}>
                                        <img src={homeKitchen} alt="homeKitchen"/>
                                        <p className={'font-futura font-15 text-center mt-4'}>Home & Kitchen</p>
                                        <Link to={'/product-list/3?from=productCategory'}>
                                            <button className={'btnTwo mt-2'}>SHOP NOW</button>
                                        </Link>
                                    </div>
                                    <div className={'text-center'}>
                                        <img src={beautyPersonalCare} alt="beautyPersonalCare"/>
                                        <p className={'font-futura font-15 text-center mt-4'}>Beauty & Personal Care</p>
                                        <Link to={'/product-list/4?from=productCategory'}>
                                            <button className={'btnTwo mt-2'}>SHOP NOW</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5 p-5">
                            <div className="container">
                                <div
                                    className="col-12 px-4 d-flex flex-xl-row flex-lg-row flex-md-column flex-column justify-content-start align-items-center">
                                    <div>
                                        <img className={'optics-img'} src={homeKitchenImage} alt="opticsImage"/>
                                    </div>
                                    <div className={'p-xl-5 p-lg-5 p-md-5 p-0'}>
                                        <h2 className={'font-openSans font-28 mt-xl-0 mt-lg-0 mt-md-0 mt-5'}>Home and
                                            Kitchen</h2>
                                        <p className={'font-futura font-15 mt-3'}>Considering Environmental challenge
                                            Trove Zone is pride to present the most eco friendly water filters in USA,
                                            you can replace up to 1,800 plastic water bottles a year. To know more about
                                            our excitement product catalog, check out our collection.</p>
                                        <Link to={`/product-list/3?from=productCategory`}>
                                            <button className={'btnOne font-openSans mt-4'}>VIEW ALL COLLECTION</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ProductCategoryView productData={healthHouseholdCategory} itemsToShow={6}/>
            <ProductCategoryView productData={homeKitchenCategory} itemsToShow={6}/>
            <ProductCategoryView productData={beautyCategory} itemsToShow={6}/>

            <Newsletter/>
        </div>

    );
};

export default HomePage;