import React from 'react';
import {Pagination, Stack} from "@mui/material";

const PageNumber = ({page, handlePageChange, totalProds, prodPerPage}) => {

    const count = Math.ceil(totalProds / prodPerPage);


    return (
        <div>
            <Stack spacing={2}>
                <Pagination count={count} page={page} onChange={handlePageChange} variant="outlined" shape="rounded"/>
            </Stack>
        </div>
    );
};

export default PageNumber;