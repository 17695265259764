import React, {useContext} from 'react';

import bgOne from '../assets/images/3_bg.png';
import bgTwo from '../assets/images/banner_reflect.png'
import {Link} from "react-router-dom";
import DataContext from "../context/Data";

const Banner = () => {

    const {handleLinkClick} = useContext(DataContext);

    return (
        <div className={'banner-sec'}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div id="banner" className="carousel slide" data-bs-ride="carousel" data-bs-interval='3000'>
                            <ol className="carousel-indicators">
                                <li data-bs-target="#banner" data-bs-slide-to="0" className="active"></li>
                                <li data-bs-target="#banner" data-bs-slide-to="1"></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img className="d-block w-100" src={bgOne} alt="First slide"/>
                                    <div className="carousel-caption">
                                        <h2 className={'text-end font-openSans'}>TROVE ZONE SHOP</h2>
                                        <span className={'text-dark'}>Partnered With World’s Top Trusted Brands</span>
                                        <div className={'text-end'}>
                                            <Link to={`/product-list/1?from=shopAll`}>
                                                <button onClick={() => handleLinkClick('Shop All')}
                                                        className={'btnOne font-openSans mt-5'}>SHOP NOW
                                                </button>
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                                <div className="carousel-item">
                                    <img className="d-block w-100" src={bgTwo} alt="Second slide"/>
                                    <div className="carousel-caption">
                                        <h2 className={'text-end font-openSans'}>TROVE ZONE SHOP</h2>
                                        <div className={'text-end'}>
                                            <Link to={`/product-list/1?from=shopAll`}>
                                                <button onClick={() => handleLinkClick('Shop All')}
                                                        className={'btnOne font-openSans mt-5'}>SHOP NOW
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;