import React, {createContext, useState} from 'react';
import {Global_Data} from "../data/ProductsData";


const DataContext = createContext({})

const Provider = ({children}) => {

    const [selectedLink, setSelectedLink] = useState('Home');
    const [cartItems, setCartItems] = useState([]);
    const [estimatedTotal, setEstimatedTotal] = useState(0);
    const [quantity, setQuantity] = useState({});
    const [estimatedTax, setEstimatedTax] = useState(0);
    const [totalCartPrice, setTotalCartPrice] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');


    const handleEstimatedTax = () => {
        let tax = Number((estimatedTotal * 0.16).toFixed(2));
        setEstimatedTax(tax)
    };

    const handleTotalCartPrice = () => {
        let total = Number((Number(estimatedTotal) + Number(estimatedTax)).toFixed(2));
        setTotalCartPrice(total)
    }

    const handleLinkClick = (val) => {
        setSelectedLink(val)
    };

    const handleAddToCart = (id) => {
        let cartProd = Global_Data.map((elem) => elem.products.find((elem) => elem.product_id === id));
        const singleObject = Object.assign({}, ...cartProd);
        setCartItems(prevState => [...prevState, singleObject])
    };


    const handleRemoveFromCart = (id) => {
        let cartProd = cartItems.findIndex((elem) => elem.product_id === id);
        let deletedItem = cartItems.splice(cartProd, 1);
        let updatedArr = cartItems.filter((elem) => elem.product_id !== deletedItem.map((elem) => elem.id));
        setCartItems(updatedArr);
    };

    const handleTotalPayment = () => {
        let total = 0;
        let calPrice = 0
        if (quantity) {
            let quantityIds = Object.keys(quantity);
            let filteredCartItems = cartItems.filter(item => quantityIds.includes(item.product_id.toString()));
            filteredCartItems.map((elem) => {
                return (
                    calPrice += elem.product_price * (quantity[elem.product_id] - 1)
                )
            });

        }
        cartItems.map((elem) => {
            return (
                total += elem.product_price
            )
        })
        total = Number(total.toFixed(2)) + Number(calPrice.toFixed(2))
        setEstimatedTotal(total);
    };

    const handleQtyChange = (event, elemId) => {
        const {value} = event.target;
        setQuantity((prevSelectedValues) => ({
            ...prevSelectedValues,
            [elemId]: value,
        }));
    };

    const handleSearchTerm = (term) => {
        setSearchTerm(term)
    }

    const value = {
        selectedLink,
        cartItems,
        estimatedTotal,
        quantity,
        estimatedTax,
        totalCartPrice,
        searchTerm,
        handleLinkClick,
        handleAddToCart,
        handleRemoveFromCart,
        handleTotalPayment,
        handleQtyChange,
        handleEstimatedTax,
        handleTotalCartPrice,
        handleSearchTerm
    }


    return (

        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    )

}

export {Provider};
export default DataContext;