import React, {useContext, useState} from 'react';

import logo from '../assets/images/logo.png'

import {Badge, styled} from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import {Link} from "react-router-dom";
import DataContext from "../context/Data";


const Nav = () => {

    const {selectedLink, handleLinkClick, cartItems} = useContext(DataContext);

    const [isActive, setIsActive] = useState(false);


    const StyledBadge = styled(Badge)(({theme}) => ({
        '& .MuiBadge-badge': {
            right: -3,
            top: 13,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
            backgroundColor: "#ff931e"
        },
    }));

    const toggleMenu = () => {
        setIsActive(prevState => !prevState)
    };

    return (
        <div className={'nav-sec'}>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-xl-3 col-lg-12 col-md-12 col-3 text-xl-start text-lg-center text-md-center">
                        <Link onClick={() => handleLinkClick('Home')} to={'/'}> <img className={'w-75'} src={logo}
                                                                                     alt="company-logo"/></Link>
                    </div>
                    <div
                        className="col-xl-6 col-lg-8 col-md-12 d-xl-flex d-lg-flex d-md-flex d-none justify-content-center align-items-center gap-4 mt-xl-0 mt-lg-3 mt-md-3 ">
                        <Link onClick={() => handleLinkClick('Home')}
                              className={`${selectedLink === 'Home' ? 'active-Link' : 'links'}`} to={"/"}>Home</Link>
                        <Link onClick={() => handleLinkClick('Shop All')}
                              className={`${selectedLink === 'Shop All' ? 'active-Link' : 'links'}`}
                              to={`/product-list/1?from=shopAll`}>Shop All</Link>
                        <Link onClick={() => handleLinkClick('Our Brands')}
                              className={`${selectedLink === 'Our Brands' ? 'active-Link' : 'links'}`} to={'/brands'}>Our
                            Brands</Link>
                        <Link onClick={() => handleLinkClick('About Us')}
                              className={`${selectedLink === 'About Us' ? 'active-Link' : 'links'}`} to={'/about-us'}>About
                            Us</Link>
                        <Link onClick={() => handleLinkClick('FAQs')}
                              className={`${selectedLink === 'FAQs' ? 'active-Link' : 'links'}`} to={'/faq'}>FAQs</Link>
                    </div>

                    {/*for mobile view menu links*/}
                    <div
                        className={`col-12 ${isActive ? 'mobile-view-links' : 'd-none'}`}>
                        <Link id={'Home'} className={'links'} to={"/"}>Home</Link>
                        <Link id={'Our Brands'} to={'/brands'} className={'links'}>Our Brands</Link>
                        <Link id={'About Us'} className={'links'} to={'/about-us'}>About Us</Link>
                        <Link id={'FAQs'} className={'links'} to={'/faq'}>FAQs</Link>
                    </div>
                    {/*for mobile view menu links*/}

                    {/*hamburger icon*/}
                    <div className={'col-6 d-xl-none d-lg-none d-md-none d-flex'}>
                        <button className={'toggle-btn'} onClick={toggleMenu}>
                            <span className={'bar'}></span>
                            <span className={'bar'}></span>
                            <span className={'bar'}></span>
                        </button>
                    </div>
                    {/*hamburger icon*/}

                    <div
                        className="col-xl-3 col-lg-4 col-md-12 col-3 d-xl-flex d-lg-flex d-md-flex  justify-content-xl-end justify-content-lg-end justify-content-md-center align-items-center gap-1 mt-xl-0 mt-lg-3 mt-md-3">
                        {!isActive && <Link to={'/cart'}><StyledBadge
                            badgeContent={cartItems.length !== 0 ? cartItems.length : '0'} color="secondary">
                            <ShoppingCartOutlinedIcon className={'cart-icon text-dark'}/>
                        </StyledBadge></Link>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Nav;