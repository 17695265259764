import React from 'react';

const Loader = () => {
    return (
        <div className={'loader-main d-flex justify-content-center align-items-center'}>
            <div className="custom-loader"></div>
        </div>
    );
};

export default Loader;