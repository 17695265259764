import React, {useEffect} from 'react';

import {Link} from "react-router-dom";

import {brands} from "../data/BrandsList";

const BrandsPage = () => {

    const renderBrands = brands.map((elem) => {
        return (
            <Link to={`/product-list/1?from=${encodeURIComponent(elem.name)}`}>
                <div className={'proj-imgbx'}>
                    <img src={elem.image} alt=""/>
                    <div className="proj-txtx">
                        <h4>{elem.name}</h4>
                    </div>
                </div>
            </Link>
        )
    })

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    return (
        <div className={'brands-page'}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className={'font-openSans font-28 text-center'}>OUR BRANDS</h1>
                        <div className={'d-flex flex-wrap justify-content-center gap-4 mt-5'}>
                            {renderBrands}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrandsPage;