import React, {useContext, useEffect} from 'react';
import Divider from "@mui/material/Divider";
import {Box, Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import DataContext from "../context/Data";

const CartBag = ({cartBagItems}) => {

    const {handleRemoveFromCart, quantity, handleTotalPayment, handleQtyChange} = useContext(DataContext);


    const totalPrice = (unitPrice, qty) => {
        return Number(unitPrice) * Number(qty)
    };


    const qty = [1, 2, 3, 4, 5, 6];

    const qtyMenu = qty.map((elem) => {
        return (
            <MenuItem sx={{color: '#ff931e'}} key={elem} value={elem}>{elem}</MenuItem>
        )
    });

    useEffect(() => {
        handleTotalPayment();
    });


    const cart = cartBagItems.map((elem) => {
        return (
            <>
                <div key={elem.id} className="row mt-3 p-3">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12 text-center">
                        <img className={'w-75'} src={elem.product_image} alt={'item'}/>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12 d-flex flex-column justify-content-between">
                        <div className="row">
                            <div className="col-12">
                                <p className={'font-openSans font-21'}>{elem.product_title}</p>
                                <p className={'font-futura font-15'}>{elem.product_description}</p>
                                <p className={'font-openSans font-21 mt-3'}>${elem.product_price}</p>
                                <div>
                                    <Box sx={{maxWidth: 110}}>
                                        <FormControl fullWidth>
                                            <InputLabel sx={{color: '#ff931e'}} id={elem.product_id}>Qty</InputLabel>
                                            <Select
                                                sx={{
                                                    color: "#ff931e",
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#ff931e',
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#ff931e',
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#ff931e',
                                                    },
                                                    '.MuiSvgIcon-root ': {
                                                        fill: "#ff931e !important",
                                                    }
                                                }}

                                                labelId="demo-simple-select-label"
                                                id={elem.product_id}
                                                value={quantity[elem.product_id] || 1}
                                                label="Age"
                                                onChange={(event) => handleQtyChange(event, elem.product_id)}
                                            >
                                                {qtyMenu}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6 d-flex justify-content-between align-items-start">
                                <Button onClick={() => handleRemoveFromCart(elem.product_id)} size="small"
                                        color={'error'}
                                        variant={'contained'}>Remove</Button>
                            </div>
                            <div className="col-6 text-end">
                                <span>Total: </span>
                                <span
                                    className={'cart-total-item-price'}>${totalPrice(elem.product_price, quantity[elem.product_id] ? quantity[elem.product_id] : 1)}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider sx={{borderBottom: '3px solid #ff931e'}} className={'mt-4'} color={'#ff931e'}/>
            </>
        )
    });

    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                                <h3 className={'font-openSans font-28'}>SHOPPING BAG</h3>
                                <div className={'font-futura font-21'}>{cartBagItems.length} Items</div>
                            </div>
                        </div>
                        <Divider color={'#ff931e'}/>
                        {cart.length !== 0 ? cart :
                            <div className={'d-flex justify-content-center align-items-center mt-5'}><h1
                                className={'font-openSans font-21'}>Your shopping bag is empty!</h1></div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartBag;